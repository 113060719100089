import styled from 'styled-components';
import {device} from '../Common/device';
import {SectionHeading,SectionSubHeading,Commonh6} from '../Common/common.style';
import 'bootstrap/dist/css/bootstrap.min.css';
import {ChevronDown} from '@styled-icons/fa-solid/ChevronDown';
import {ChevronUp} from '@styled-icons/fa-solid/ChevronUp';
import BackgroundImg from '../../../assets/realestate-5-images/map.jpg';
const OPACITY = 0.8;

export const NeighbourSection = styled.section`
    background-image:url(${BackgroundImg});
    background-repeat:no-repeat;
    background-size:cover;
    background-position:center;
`;

export const SectionWrapper = styled.div`
    background:rgba(0,0,0,${OPACITY});
    display:flex;
    align-items:center;
    padding:100px 0px;

    @media ${device.tablet} {
        padding:80px 10px;
    }
`;

export const HeadingLayout = styled.div`
    margin-bottom:60px;

    @media ${device.tablet} {
        margin-bottom:50px;
    }
`;

export const Heading = styled(SectionHeading)`
    margin-bottom:10px;
    color:#fff;
`;

export const SubHeading = styled(SectionSubHeading)`
    color:#fff;
`;

export const NeighbourhoodLayout = styled.div`
    display:flex;
    height:350px;

    @media ${device.tablet} {
        flex-direction:column;
        height:auto;
    }  
`;

export const ImageHolder = styled.div`
    width:65%;

    @media ${device.laptop} {
        width:60%;
    }

    @media ${device.tablet} {
        width:100%;
    }

    .neighbourhoodImg{
        height:350px;
    }
`;


export const AccordianWrapper = styled.div`
    width:35%;
    overflow:scroll;
    padding:5px 20px;
    background:#fff;

    @media ${device.laptop} {
        width:40%;
    }

    @media ${device.tablet} {
        width:100%;
        height:300px;
    }  
`;

export const AccordianRow = styled.div`
`;

export const Accordian = styled.div`
    overflow: hidden;
`;

export const AccordianTitleCol = styled.div`
    display:flex;
    align-items:center;
    transition: all .5s;
    border-bottom:1px solid #eaeaea;

    :hover{
        transition: all .5s;
    }
`;

export const AccordianTitle = styled(Commonh6)`
    padding: 15px 0px 15px;
    overflow:hidden;
    margin-bottom:0px;
    cursor:pointer;
    color:#282828;
    flex-grow: 1;
    line-height:1;
    font-weight:700;
    text-align:left;

    @media ${device.tablet} {
        text-align:left;
    }

    @media ${device.mobileXL} {
        line-height:1;
    }
`;

export const AccordianContentCol = styled.div`
    padding: 0px 40px 15px 0px;

    ${props => props.isActive &&`
        
    `}

    ${props => !props.isActive &&`

    `}
`;
export const AccordianContent = styled.p`
    color: #565759;
    font-size:15px;
    margin-bottom:0px;
    padding:10px 0px;
`;

export const IconHolder = styled.div`
    background:#bfca02;
    display:flex;
    align-items:center;
    border-radius:100%;
    width: 20px;
    height:20px;
`;

export const PlusIcon = styled(ChevronUp)`
    color: #fff;
    width: 10px;
    flex-shrink:0;
    margin:5px;
`;

export const MinusIcon = styled(ChevronDown)`
    color: #fff;
    width: 10px;
    flex-shrink:0;
    margin:5px;
`;


