import React,{Component} from 'react';
import {PropertiesSection,HeadingLayout,Heading,SubHeading,CustomTabList,SliderOuterWrapper,
    ImgButtonLeft,LeftIcon,PropertiesSliderWrapper,PropertyLayout,CustomRow,ImageHolder,TextLayout,
    TabHeading,TabPara1,SizeText,SpecLayout,SpecIcon,SpecText,ImgButtonRight,RightIcon
} from './properties.style';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Tab, Tabs, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";
import { StaticQuery, graphql } from 'gatsby';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import GatsImg from "gatsby-image";

class Properties extends Component{

    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    render() {
        const settings = {
            arrows:false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
        };
        
        return(
            <PropertiesSection id="propertiesSection">
                <Container>

                    <HeadingLayout>
                        <Heading>
                            {this.props.PropertiesData.Heading}
                        </Heading>
                        <SubHeading>
                            {this.props.PropertiesData.SubHeading}
                        </SubHeading>
                    </HeadingLayout>

                    <Tabs>
                        <CustomTabList>
                        {
                            this.props.PropertiesData.TabList.map((item,idx) => {
                                return <Tab>{item.Tab}</Tab>
                            })
                        }

                        </CustomTabList>
                        {
                            this.props.PropertiesData.TabList.map((itemTab,idxTab) => {
                            return <TabPanel>
                                    <SliderOuterWrapper>
                                        <ImgButtonLeft onClick={this.previous} aria-label="Prev Button">
                                            <LeftIcon />
                                        </ImgButtonLeft>
                                        <Slider ref={c => (this.slider = c)} {...settings}>
                                        {
                                            itemTab.TabPanel.map((itemPanel,idxPanel) => {
                                            return <PropertiesSliderWrapper>
                                                    <PropertyLayout>
                                                        <CustomRow>

                                                            <Col lg={6}>
                                                                <ImageHolder>
                                                                    <GatsImg 
                                                                        fluid={itemPanel.Img.childImageSharp.fluid} 
                                                                        alt=""
                                                                        className="propertyImg"
                                                                    />
                                                                </ImageHolder>
                                                            </Col>

                                                            <Col lg={6}>
                                                                <TextLayout>
                                                                    <TabHeading>{itemPanel.TabHeading}</TabHeading>
                                                                    <TabPara1>{itemPanel.TabPara1}</TabPara1>

                                                                    <SizeText>
                                                                    {itemPanel.SizeText}
                                                                    </SizeText>
                                                                    <Row>
                                                                        {
                                                                            itemPanel.SpecLayout.map((spec,specIdx) => {
                                                                                return <Col lg={6} md={6} sm={6} xs={12}>
                                                                                <SpecLayout>
                                                                                    <SpecIcon src={spec.SpecIcon} alt=""/>
                                                                                    <SpecText>
                                                                                    {spec.SpecText} 
                                                                                    </SpecText>
                                                                                </SpecLayout>
                                                                                </Col>
                                                                            })
                                                                        }
                                                                    </Row>
                                                                </TextLayout>
                                                            </Col>
                                                        </CustomRow>
                                                    </PropertyLayout>
                                                </PropertiesSliderWrapper>
                                            })
                                        }
                                        </Slider>
                                        <ImgButtonRight onClick={this.next} aria-label="Next Button">
                                            <RightIcon />
                                        </ImgButtonRight>
                                    </SliderOuterWrapper>
                                </TabPanel>
                            })
                        }
                    </Tabs>
                </Container>
            </PropertiesSection>
        );
    }
}


export default () => (
<StaticQuery
    query={graphql`
        query{
            realestatePage5Json {
                Properties {
                    SubHeading
                    Heading
                    TabList{
                        Tab
                        TabPanel{
                            Img{
                                childImageSharp{
                                    fluid(quality: 100){
                                    ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                            TabHeading
                            TabPara1
                            SizeText
                            SpecLayout{
                                SpecIcon
                                SpecText
                            }
                        }
                    }
                }
            }
        }
        
    `}

    render={(data) => (
        <Properties PropertiesData={data.realestatePage5Json.Properties}/>
    )}
/>
)