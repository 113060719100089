import React,{Component} from 'react';
import {Container} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {TestimonialsSection,SectionWrapper,HeadingLayout,Heading,SubHeading,
    SliderOuterWrapper,
    TestimonialSliderWrapper,TestimonialSliderLayout,TestimonialTextLayout,
    QuotesIcon,ProfileLayout,AuthorImageHolder,
    ProfileNameLayout,ProfileName,Designation,Testimonial,NextPrev,ImgButton,PrevImg,NextImg
} from './testimonials.style';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { StaticQuery, graphql } from 'gatsby';
import GatsImg from "gatsby-image";

class Testimonials extends Component{

    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    render() {
        const settings = {
            arrows:false,
            infinite: true,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                }
            ]
        };
        
        return(
            <TestimonialsSection id="testimonialsSection">
                <SectionWrapper>
                    <Container>
                        <HeadingLayout>
                            <Heading>
                            {this.props.TestimonialsData.Heading} 
                            </Heading>
                            <SubHeading>
                            {this.props.TestimonialsData.SubHeading} 
                            </SubHeading>
                        </HeadingLayout>
                        
                        <SliderOuterWrapper>
                            <Slider ref={c => (this.slider = c)} {...settings}>
                            {
                                this.props.TestimonialsData.ProfileLayout.map((item,idx) => {
                                    return <TestimonialSliderWrapper>
                                        <TestimonialSliderLayout>

                                            <AuthorImageHolder>
                                                <GatsImg 
                                                    fluid={item.ProfilePic.childImageSharp.fluid} 
                                                    className="profileImg"
                                                    alt=""
                                                />
                                            </AuthorImageHolder>

                                            <TestimonialTextLayout>
                                                
                                                <ProfileLayout>
                                                    <ProfileNameLayout>
                                                        <ProfileName>
                                                            {item.ProfileName}
                                                        </ProfileName>
                                                        <Designation>
                                                            {item.Designation}
                                                        </Designation>
                                                    </ProfileNameLayout>
                                                    <QuotesIcon/>
                                                </ProfileLayout>

                                                <Testimonial>
                                                    {item.Testimonial}
                                                </Testimonial>
                                            </TestimonialTextLayout>

                                        </TestimonialSliderLayout>
                                    </TestimonialSliderWrapper>
                                })
                            }
                            </Slider>
                        </SliderOuterWrapper>
                        <NextPrev>
                            <ImgButton onClick={this.previous} aria-label="Prev Button">
                                <PrevImg src={this.props.TestimonialsData.PrevImg} alt=""/>
                            </ImgButton>
                            <ImgButton onClick={this.next} aria-label="Next Button">
                                <NextImg src={this.props.TestimonialsData.NextImg} alt=""/>
                            </ImgButton>
                        </NextPrev>
                    </Container>
                </SectionWrapper>
            </TestimonialsSection>
        );
    }
}


export default () => (
    <StaticQuery
        query={graphql`
            query {
                    realestatePage5Json{
                        Testimonials{
                            Heading
                            SubHeading
                            ProfileLayout{
                                ProfilePic{
                                    childImageSharp{
                                        fluid(quality: 100){
                                        ...GatsbyImageSharpFluid
                                        }
                                    }
                                }
                                ProfileName
                                Designation
                                Testimonial
                            }
                            PrevImg
                            NextImg
                        }
                    }
                }
            
        `}
        render={(data) => (
            <Testimonials TestimonialsData={data.realestatePage5Json.Testimonials}/>
        )}
    />
  )