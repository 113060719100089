import styled from 'styled-components';
import {SectionHeading,SectionSubHeading,Commonpara,Commonh5} from '../Common/common.style';
import {device} from '../Common/device';
import {Row} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

export const FeaturesSection = styled.section`
    padding:100px 0px 70px;

    @media ${device.tablet}{
        padding:80px 10px 50px;
    }
`;

export const HeadingLayout = styled.div`
    margin-bottom:60px;

    @media ${device.tablet}{
        margin-bottom:50px;
    }
`;

export const Heading = styled(SectionHeading)`
`;

export const SubHeading = styled(SectionSubHeading)`
`;

export const FeaturesRow = styled(Row)`
    margin-left:-35px; //15px margin in Col, 20px margin in FeaturesCard
    margin-right:-35px; 

    @media ${device.laptop} {
        margin-left:-15px;
        margin-right:-15px;
    }
`;

export const FeaturesWrapper = styled.div`
`;

export const FeaturesCard = styled.div`
    background:#fff;
    box-shadow: 0px 0px 15px 1px #ddd;
    margin:0px 20px 30px;

    @media ${device.laptop} {
       margin:0px 0px 30px;
    }
`;

export const FeatureImgHolder = styled.div`
    .featureImg{
        height:200px;
    }
`;

export const FeaturesCardIcon = styled.img`
    height: 70px;
    margin-top: -35px; //Half of the icon size
    margin-left: 20px;
    position: relative;
`;

export const TextLayout = styled.div`
    padding:20px;
`;

export const FeaturesCardHeading = styled(Commonh5)`
    line-height:1;
    text-align:left;
    margin-bottom:15px;

    @media ${device.tablet} {
        line-height:1;
    }
`;

export const FeaturesCardPara = styled(Commonpara)`
    margin-bottom:0px;
`;