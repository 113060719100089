import styled from 'styled-components';
import {device} from '../Common/device';
import {Commonpara,Commonh4} from '../Common/common.style';
import BackgroundImg from '../../../assets/realestate-5-images/stats-parallax.jpg';

export const StatsSection = styled.section`
    background-image:url(${BackgroundImg});   
    background-repeat:no-repeat;
    background-size:cover;

    padding:100px 0px 70px;

    @media ${device.tablet} {
        padding:80px 10px 50px;
    }
`;

export const StatsSingle = styled.div`
    text-align:center;
    margin-bottom:30px;
`;

export const StatsImg = styled.img`
    width: auto;
    height: 80px;
    margin-bottom:20px;
`;

export const Heading = styled(Commonh4)`
    line-height:1;
    margin-bottom:10px;
    color:#fff;

    @media ${device.tablet} {
        line-height:1;
    }
`;

export const StatsDesc = styled(Commonpara)`
    margin-bottom:0px;
    color:#fff;
    line-height:1;
`;
