import React from 'react';
import {AboutSection,SectionWrapper,CustomRow,LeftLayout,HeadingLayout,Heading,SubHeading,
    Description1,Description2,ServicesLayout,ServicesImg,
    ServicesTextLayout,ServicesHeading,ServicesDesc,ImageHolder
} from './about.style';
import {Container,Row,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import GatsImg from "gatsby-image";
import { useStaticQuery, graphql } from 'gatsby';

const About = () => {
    const JSONData  = useStaticQuery(graphql`
        query {
                realestatePage5Json{
                    About{
                       Heading
                       SubHeading
                       Description1
                       Description2
                       Img{
                            childImageSharp{
                                fluid(quality: 100){
                                ...GatsbyImageSharpFluid
                                }
                            }
                       }
                       ServicesImg1
                       ServicesHeading1
                       ServicesDesc1
                       ServicesImg2
                       ServicesHeading2
                       ServicesDesc2
                    }
                }
            }
    `);
    const AboutData = JSONData.realestatePage5Json.About;
    
    return (
        <AboutSection id="aboutSection">
            <SectionWrapper>
                <Container>
                    <CustomRow>
                        <Col lg={6}>
                            <LeftLayout>
                                <HeadingLayout>
                                    <Heading>
                                        {AboutData.Heading}
                                    </Heading>
                                    <SubHeading>
                                        {AboutData.SubHeading}
                                    </SubHeading>
                                </HeadingLayout>
                                <Description1>
                                {AboutData.Description1} 
                                </Description1>
                                <Description2>
                                {AboutData.Description2} 
                                </Description2>
                            
                                <Row>
                                    <Col md={6} sm={6} xs={12}>
                                        <ServicesLayout>
                                            <ServicesImg 
                                                src={AboutData.ServicesImg1} 
                                                alt=""
                                            />
                                            <ServicesTextLayout>
                                                <ServicesHeading>
                                                {AboutData.ServicesHeading1} 
                                                </ServicesHeading>
                                                <ServicesDesc>
                                                {AboutData.ServicesDesc1}
                                                </ServicesDesc>
                                            </ServicesTextLayout>
                                        </ServicesLayout>
                                    </Col>
                                    <Col md={6} sm={6} xs={12}>
                                        <ServicesLayout>
                                            <ServicesImg 
                                                src={AboutData.ServicesImg2} 
                                                alt=""
                                            />
                                            <ServicesTextLayout>
                                                <ServicesHeading>
                                                {AboutData.ServicesHeading2} 
                                                </ServicesHeading>
                                                <ServicesDesc>
                                                {AboutData.ServicesDesc2}
                                                </ServicesDesc>
                                            </ServicesTextLayout>
                                        </ServicesLayout>
                                    </Col>
                                </Row>
                            </LeftLayout>
                        </Col>
                        <Col lg={6}>
                            <ImageHolder>
                                <GatsImg 
                                    fluid={AboutData.Img.childImageSharp.fluid} 
                                    alt=""
                                />
                            </ImageHolder>
                        </Col>
                    </CustomRow>
                </Container>
            </SectionWrapper>
        </AboutSection>
    );
}

export default About;