import styled from 'styled-components';
import {SectionHeading,SectionSubHeading,Commonh6} from '../Common/common.style';
import {device} from '../Common/device';
import BackgroundImg from '../../../assets/realestate-5-images/about-section-bg.jpg';
import {ChevronLeft} from '@styled-icons/fa-solid/ChevronLeft';
import {ChevronRight} from '@styled-icons/fa-solid/ChevronRight';
import {Row} from 'react-bootstrap';

export const AmenitiesSection = styled.section`
    background-image:url(${BackgroundImg}) !important;
    background-repeat:no-repeat;
    background-size:cover;
    background-position:center;
`;

export const SectionWrapper = styled.div`
    padding:100px 0px;
    
    @media ${device.tablet} {
        padding:80px 10px;
    }
`;

export const HeadingLayout = styled.div`
    margin-bottom:30px;
`;

export const Heading = styled(SectionHeading)`
    text-align:left;
    margin-bottom:10px;
`;

export const SubHeading = styled(SectionSubHeading)`
    text-align:left;
`;

export const CustomRow = styled(Row)`
    align-items:center;
`;

export const AmenitiesLayout = styled.div`
    display:flex;
    align-items:center;
    margin-bottom:20px;
`;

export const AmenitiesImage = styled.img`
    width:60px;
`;

export const AmenitiesText = styled(Commonh6)`
    line-height:1;
    margin-bottom:0;
    margin-left:10px;
    text-align:left;
    font-weight:700;

    @media ${device.mobileXL} {
        line-height:1;
    }
`;

export const SliderWrapper = styled.div`
    .slick-list{
        line-height:0;
    }
`;

export const ImgButtonLeft = styled.button`
    line-height:0px;
    padding-bottom:0px;
    border:none;
    background:none;
    outline:none;
    position:absolute;
    padding:15px;
    top:calc(50% - 25px);
    z-index:99;
    background:rgba(0,0,0,0.4);
    left: 15px;

    :focus{
        outline:0;
    }
`;

export const LeftIcon = styled(ChevronLeft)`
    width:20px;
    height:20px;
    color:#fff;
`;

export const SliderLayout = styled.div`
    
    @media ${device.laptop} {
        margin-top:20px;
    }
    .amenitiesImg{
        height:500px;

        @media ${device.tablet} {
            height:400px;
        }
    }
`;

export const ImgButtonRight = styled.button`
    line-height:0px;
    padding-bottom:0px;
    border:none;
    background:none;
    outline:none;
    position:absolute;
    padding: 15px;
    top:calc(50% - 25px);
    z-index:99;
    background:rgba(0,0,0,0.4);
    right: 15px;

    :focus{
        outline:0;
    }
`;

export const RightIcon = styled(ChevronRight)`
    width:20px;
    height:20px;
    color:#fff;
`;



