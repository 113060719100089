import React from 'react';
import {NeighbourSection,SectionWrapper,HeadingLayout,SubHeading,Heading,
    NeighbourhoodLayout,AccordianWrapper,ImageHolder
} from './neighbour.style';
import {Container} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import GatsImg from "gatsby-image";
import { useStaticQuery, graphql } from 'gatsby';
import AccordianCustom from './AccordianCustom';

const Neighbour = () => {
    const JSONData  = useStaticQuery(graphql`
        query {
                realestatePage5Json{
                    Neighbour{
                        SubHeading
                        Heading
                       Img{
                            childImageSharp{
                                fluid(quality: 100){
                                ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        AccordianCustom{
                            title
                            description
                        }
                    }
                }
            }
    `);
    const NeighbourData = JSONData.realestatePage5Json.Neighbour;

        return (
        <NeighbourSection id="neighbourSection">
            <SectionWrapper>
                <Container>
                    <HeadingLayout>
                        <Heading>
                            {NeighbourData.Heading}
                        </Heading>
                        <SubHeading>
                            {NeighbourData.SubHeading}
                        </SubHeading>
                    </HeadingLayout>

                    <NeighbourhoodLayout>
                        <AccordianWrapper>
                        {
                            NeighbourData.AccordianCustom.map((item,idx) => {
                            return <AccordianCustom 
                                title={item.title}
                                description= {item.description} 
                            />
                            })
                        }
                        </AccordianWrapper>
                        <ImageHolder>
                            <GatsImg 
                                fluid={NeighbourData.Img.childImageSharp.fluid} 
                                alt=""
                                className="neighbourhoodImg"
                            />
                        </ImageHolder>
                    </NeighbourhoodLayout>
                </Container>
            </SectionWrapper>
        </NeighbourSection>
    );
}

export default Neighbour;