import React from 'react';
import {FeaturesSection,HeadingLayout,Heading,SubHeading,FeaturesRow,FeaturesWrapper,
    FeaturesCard,FeatureImgHolder,FeaturesCardIcon,
    TextLayout,FeaturesCardHeading,FeaturesCardPara
} from './features.style';
import {Container, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useStaticQuery, graphql } from 'gatsby';
import GatsImg from "gatsby-image";

const Features = () => {  
    const JSONData  = useStaticQuery(graphql`
        query{
            realestatePage5Json {
                Features {
                    Heading
                    SubHeading
                    FeaturesWrapper{
                        FeaturesCardImg{
                            childImageSharp{
                                fluid(quality: 100){
                                ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        FeaturesCardIcon
                        FeaturesCardHeading
                        FeaturesCardPara
                    }
                }
            }
        }
    `);
    const FeaturesData = JSONData.realestatePage5Json.Features;

    return(
        <FeaturesSection id="featuresSection">
            <Container>
                
                <HeadingLayout>
                    <Heading>
                    {FeaturesData.Heading}
                    </Heading>
                    <SubHeading>
                    {FeaturesData.SubHeading}
                    </SubHeading>
                </HeadingLayout>

                <FeaturesWrapper>
                    <FeaturesRow>
                    { 
                        FeaturesData.FeaturesWrapper.map((item,idx) => {
                        return <Col lg="4" md="12" sm="12">
                                    <FeaturesCard>
                                        <FeatureImgHolder>
                                            <GatsImg 
                                                fluid={item.FeaturesCardImg.childImageSharp.fluid} 
                                                alt=""
                                                className="featureImg"
                                            />
                                        </FeatureImgHolder>
                                        <FeaturesCardIcon src={item.FeaturesCardIcon} alt=""/>
                                        <TextLayout>
                                            <FeaturesCardHeading>{item.FeaturesCardHeading}</FeaturesCardHeading>
                                            <FeaturesCardPara>{item.FeaturesCardPara}</FeaturesCardPara>
                                        </TextLayout>
                                    </FeaturesCard>
                            </Col>
                        })
                    }
                    </FeaturesRow>
                </FeaturesWrapper>
            </Container>
        </FeaturesSection>
    )
}
export default Features;