import styled from 'styled-components';
import {device} from '../Common/device';
import {SectionHeading,SectionSubHeading,Commonpara,Commonh5} from '../Common/common.style';
import {Row} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import BackgroundImg from '../../../assets/realestate-5-images/about-section-bg.jpg';

export const AboutSection = styled.section`
    background-image:url(${BackgroundImg});
    background-repeat:no-repeat;
    background-size:cover;
    background-position:center;
`;

export const SectionWrapper = styled.div`
    padding:100px 0px;
    
    @media ${device.tablet} {
        padding:80px 10px;
    }
`;

export const CustomRow = styled(Row)`
    align-items:center;
`;

export const LeftLayout = styled.div`
    padding-right:10px;

    @media ${device.laptop} {
        padding-right:0px;
    }
`;

export const HeadingLayout = styled.div`
    margin-bottom:30px;
`;

export const Heading = styled(SectionHeading)`
    text-align:left;
`;

export const SubHeading = styled(SectionSubHeading)`
    text-align:left;
`;

export const Description1 = styled(Commonpara)`
`;

export const Description2 = styled(Commonpara)`
`;

export const ServicesLayout = styled.div`
    display:flex;
    align-items:center;
    margin-top:10px;

    @media ${device.mobileXL}{
        margin-bottom:10px;
    }
`;

export const ServicesImg = styled.img`
    width:50px;
    height:50px;
`;

export const ServicesTextLayout = styled.div`
    margin-left:10px;
`;

export const ServicesHeading = styled(Commonh5)`
    line-height:1;
    margin-bottom:5px;
    text-align:left;

    @media ${device.tablet} {
        line-height:1;
    }
`;

export const ServicesDesc = styled(Commonpara)`
    line-height:1;
    text-align:left;
    margin-bottom:0px;
`;

export const ImageHolder = styled.div`
    padding-left:10px;

    @media ${device.laptop} {
        padding-left:0px;
        margin-top:40px;
    }

    @media ${device.mobileXL}{
        margin-top:30px;
    }
`;




